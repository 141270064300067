import React, { useState, useCallback } from 'react';
//import { render } from 'react-dom';
//import ImageViewer from 'react-simple-image-viewer';

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

const photos = [
  {
    src: "/photo21.jpg",
    width: 16,
    height: 11
  },
  {
    src: "/photo22.jpg",
    width: 16,
    height: 11
  },
  {
    src: "/photo27.jpg",
    width: 16,
    height: 11
  },
  {
    src: "/photo18.jpg",
    width: 11,
    height: 16
  },
  {
    src: "/photo8.jpg",
    width: 16,
    height: 11
  },
  {
    src: "/photo19.jpg",
    width: 16,
    height: 11
  },
];
export default function Home() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}
